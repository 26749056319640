import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import PageNotFound from './components/page_not_found'
import NavBar from './components/navbar';
import Footer from './components/footer'
import Home from './components/home'
import About from './components/about'
import BeyondMotivation from './components/beyond_motivation'
import Classes from './components/classes'
import VirtualBookings from './components/virtual_booking'
import SignIn from './components/signin'
import Dashboard from './components/dashboard'
import EmailVerification from './components/email_verification'
import VerifyEmail from './components/verify_email'
import Programs from './components/programs'
import Program from './components/program'
import Payments from './components/payments'


class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };
  }
  componentDidMount() {

  }

  render() {
    return (
          <div className="App" style={{}}>
                  <BrowserRouter>
                    <NavBar/>
                    <Switch>
                      <Route path='/' exact={true} component={Home}/>
                      {/* <Route path='/seminars' exact={true} component={Programs}/>
                      <Route path='/seminar/:program_id' exact={true} component={Program}/> */}
                      <Route path='/about' exact={true} component={About}/>
                      <Route path='/videos' exact={true} component={BeyondMotivation}/>
                      <Route path='/programs' exact={true} component={Classes}/>
                      <Route path='/registration/:program_name' exact={true} component={Payments}/>
                      <Route path='/virtual-bookings' exact={true} component={VirtualBookings}/>
                      <Route path='/signin' exact={true} component={SignIn}/>
                      <Route path='/dashboard' exact={true} component={Dashboard}/>
                      <Route path='/email-verification/:email' exact={true} component={EmailVerification}/>
                      <Route path='/verify-email/:token' exact={true} component={VerifyEmail}/>
                      <Route path="*" component={PageNotFound} />
                    </Switch>
                    <Footer/>
                  </BrowserRouter>    
          </div>
    );
  }
}

export default withCookies(App);
