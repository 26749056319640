import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image from '../images/speaker.png'


class Programs extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
        programs: [],
        loading: true
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.CheckForPrograms = () => {
        var programs = this.state.programs

        if(programs.length == 0){
            return<div>
                <img src={Image} style={{width: '30%'}}/>
                <h6 style={{fontWeight: 'bold'}}>No programs have been uploaded yet.</h6>
            </div>
        }else{
            var programs_map = programs.map((prog, index) => {
                if(true){
                    return<Row style={{minHeight: '450px', backgroundColor: '#ffffff', marginBottom: '15px'}}>
                    <Col sm='6'>
                        <br/><br/>
                        <div style={{height: '', overflow: 'hidden', width: '100%'}}>
                            <img src={Backend_Url + 'images/' + prog.image} style={{width: '100%'}}/>
                        </div>
                        <br/>
                    </Col>
                    <Col>
                      <br/><br/><br/>
                      <Container style={{textAlign: 'left'}}>
                        <h5 style={{fontWeight: 'bold', color: '#020B30', textAlign: 'left'}}>
                          {prog.name}
                        </h5>
                        <br/>
                        <h6 style={{fontWeight: 'bold', textAlign: 'left'}}>
                          {prog.speakers}
                        </h6>
                        <br/>
                        <p>
                          {prog.details} 
                        </p>
                        <br/><br/><br/>
                        <Button href={'/seminar/'+prog._id.$oid} style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                          READ MORE
                        </Button>
                      </Container>
                      <br/><br/><br/>
                    </Col>
                  </Row>
                }
            })
            return<div>
                {programs_map}
            </div>
        }
    }
  }

  componentDidMount() {
    const { cookies } = this.props;

    var data = new FormData()
    data.append('user_access_token', cookies.get('token'))

    axios.post(Backend_Url + 'programs', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
    .then((res) => {
      let result = res.data
      this.setState({programs: result, loading: false})
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    if(this.state.loading == true){
      return(
        <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
          <Helmet>
            <title>Seminars | Liberty Makuyana</title>
            {/* <meta name="description" content="" /> */}
          </Helmet>
          <br/><br/>
          <Container>
            <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Seminars</h3>
            <br/><br/>
            <h5>
              Loading ...
            </h5>
          </Container>
          <br/><br/><br/>
        </div>
      );
    }

    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Seminars | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
          <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Seminars</h3>
          <br/><br/>
          <this.CheckForPrograms/>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(Programs);