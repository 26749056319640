import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Bac_img from '../images/image_1.jpeg'
import {AiFillEye, AiFillShopping, AiOutlineUser, AiOutlineShoppingCart, AiFillFileText} from 'react-icons/ai'
import AddProgram from './add_program'
import AddVideo from './add_video'
import { FaNotesMedical, FaVideo } from 'react-icons/fa';

class Dashboard extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
        current_render: 'add_program'
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.ChangeRender = (selected_render) => {
        this.setState({current_render: selected_render})
    }

    this.RenderSelected = () => {
        var current_render = this.state.current_render
        if(current_render == 'add_program'){
            return<AddProgram/>
        }else if(current_render == 'add_video'){
          return<AddVideo/>
        }
    }
  }

  componentDidMount() {
    const { cookies } = this.props;
    if(cookies.get('token') == null){
      let port = (window.location.port ? ':' + window.location.port : '');
      window.location.href = '//' + window.location.hostname + port + '/signin';
    }else{

      axios.get(Backend_Url + 'getUserDetailsByAccessToken/' + cookies.get('token'), { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
      .then(res => {
        this.setState({
          user_details: res.data
        });
        var user_role = res.data.role
  
        if(user_role != 'admin'){
          let port = (window.location.port ? ':' + window.location.port : '');
          window.location.href = '//' + window.location.hostname + port + '/';
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Admin Dashboard - Beyond Motivation</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <Row style={{backgroundImage: "url(" + Bac_img + ")", margin: '0px', minHeight: '550px'}}>
            <Col sm='2' style={{backgroundColor: '#020B30'}}>
                <br/><br/>
                <Button onClick={() => this.ChangeRender('add_program')} style={{textAlign: 'left', border: 'none', width: '100%', color: '#ffffff', backgroundColor: 'inherit'}}>
                    <AiFillFileText size='20px' color='#ffffff'/> Add Seminar
                </Button>
                <br/><br/>
                <Button onClick={() => this.ChangeRender('add_video')} style={{textAlign: 'left', border: 'none', width: '100%', color: '#ffffff', backgroundColor: 'inherit'}}>
                    <FaVideo size='20px' color='#ffffff'/> Add Video
                </Button>
            </Col>
            <Col style={{backgroundColor: '#ffffff', opacity: 0.9}}>
                <this.RenderSelected/>
            </Col>
        </Row>
      </div>
    );
  }

};
  
export default withCookies(Dashboard);