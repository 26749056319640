import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image from '../images/image_4.jpeg'


class About extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>About | Beyond Motivation</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
          <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>About</h3>
          <br/><br/>
          <Row>
            <Col sm='6'>
              <img src={Image} style={{width: '100%'}} />
            </Col>
            <Col>
              <span style={{fontWeight: 'bold', color: '#020B30'}}>
                ‘We don’t do this to earn a living, we do this because it’s why we are living’ -	Liberty Makuyana
              </span>
              <br/><br/>
              <p style={{textAlign: 'left'}}>
                Our core belief in Beyond Motivation is that there is a reason why every single person was created, and it is in the fulfilment of this 
                purpose in which Life finds meaning. The principles and displayed teachings apply to individuals, organisations, communities, nations, and 
                the world at large. We are driven to assist you in living within the totality of your God given purpose through equipping you, mentoring you 
                and standing as both a guide and support across our numerous programs.
                <br/><br/>
                <span style={{fontWeight: 'bold', color: '#020B30'}}>Facebook:</span> <a href='https://www.facebook.com/beyondmotivationliberty' target='_blank'>https://www.facebook.com/beyondmotivationliberty</a>
                <br/>
                <span style={{fontWeight: 'bold', color: '#020B30'}}>Instagram:</span> <a href='https://www.instagram.com/liberty_beyondmotivation/' target='_blank'>https://www.instagram.com/liberty_beyondmotivation/</a>
              </p>
            </Col>
          </Row>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(About);