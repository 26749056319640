import React, { Component, useReducer } from 'react';
import {Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, FormGroup, Container, Label, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import {FaUserAlt, FaUsers, FaPhone, FaKey, FaUserLock, FaMailBulk} from 'react-icons/fa';
import Background from '../images/image_1.jpeg'
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'

  class EmailVerification extends Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) { 
      super(props);
      this.state = {
        status: ''
      };
      this.ResendVerificationEmail = () => {
        const email = this.props.match.params.email
        axios.post(Backend_Url + 'checkEmailValidity/' + email, { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
        .then((res) => {
          let result = res.data
          if(result=='Email valid'){
              this.setState({status: 'verification email sent'})
              alert('Verification email sent.')
          }
          if(result=='Invalid email'){
              this.setState({status: 'invalid email'})
          }
          if(result=='Email already verified'){
              this.setState({status: 'email already verified'})
          }
          if(result=='Email not registered'){
            this.setState({status: 'email not registered'})
          }
        }).catch((error) => {
              console.log(error)
        })
      }
      this.SelectRender = () => {
          var status = this.state.status
          if(status=='verification email sent'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    We've sent you a verification email at <span style={{color: '#1FACED'}}>{this.props.match.params.email}</span>
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    Follow the instructions stated in the email inorder to verify your account and gain access to A&E.
                </h5>
                <h6 style={{marginTop: '100px'}}>
                    Did not receive our email? Click the button below to resend.
                </h6>
                <br/>
                <Button onClick={this.ResendVerificationEmail} style={{backgroundColor: '#1FACED', border: 'none'}}>
                  Resend verification email
                </Button>
            </div>
          }else if(status=='invalid email'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    The supplied email address (<span style={{color: '#1FACED'}}>{this.props.match.params.email}</span>) is not a valid email address
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signup' style={{color: 'inherit'}}>Click here to signup using another email.</a>
                </h5>
            </div>
          }else if(status=='email already verified'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    The supplied email address (<span style={{color: '#1FACED'}}>{this.props.match.params.email}</span>) has already been verified
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signin' style={{color: 'inherit'}}>Click here to signin.</a>
                </h5>
            </div>
          }else if(status=='email not registered'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    The supplied email address (<span style={{color: '#1FACED'}}>{this.props.match.params.email}</span>) is not registered on this platform
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signup' style={{color: 'inherit'}}>Click here to signup.</a>
                </h5>
            </div>
          }else{
            return<div>
                <br/>
                <h1 style={{marginTop: '120px', fontWeight: 'bold'}}>
                  A&E
                </h1>
                <h3 style={{marginTop: '80px'}}>
                  Loading...
                </h3>
                <h6 style={{marginTop: '50px'}}>
                    Taking too long to load? Check your internet connection and reload the page.
                </h6>
            </div>
          }
      }
    }

    componentDidMount() {
      const { cookies } = this.props;
      if(cookies.get('token')!=null){
        let port = (window.location.port ? ':' + window.location.port : '');
        window.location.href = '//' + window.location.hostname + port + '/dashboard';
      }else{
        const email = this.props.match.params.email
        axios.post(Backend_Url + 'checkEmailValidity/' + email, { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
        .then((res) => {
          let result = res.data
          if(result=='Email valid'){
              this.setState({status: 'verification email sent'})
          }
          if(result=='Invalid email'){
              this.setState({status: 'invalid email'})
          }
          if(result=='Email already verified'){
              this.setState({status: 'email already verified'})
          }
          if(result=='Email not registered'){
            this.setState({status: 'email not registered'})
          }
        }).catch((error) => {
              console.log(error)
        })
      }
  }

    render() {
      return (
        <div style={{backgroundImage: "url(" + Background + ")"}}>
        <div style={{minHeight: '500px', backgroundColor: '#FFFFFF', opacity: 0.85}}>
            <Helmet>
            <title>Email verification - A&E</title>
            </Helmet>
          <this.SelectRender/>
        </div>
      </div>
      );
    }

  };
  
  export default withCookies(EmailVerification);