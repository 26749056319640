import React, { Component, useReducer } from 'react';
import {Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { AiOutlineSearch, AiOutlineUser } from "react-icons/ai";
import {Backend_Url} from './backend_url'
import Logo from '../images/logos/logo.jpg'
import {AiOutlineShoppingCart} from 'react-icons/ai'

  class NavBar extends Component{
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) { 
      super(props);
      this.state = {
        isOpen: false,
        dropdownOpen: false,
        user_details: {}
      };    
      
      this.HandleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value});
      };

      this.AdminRender = () => {
        if(this.state.user_details.role == 'admin'){
          return<NavItem>
              <NavLink>
              <Button href="/dashboard" style={{backgroundColor: 'inherit', borderRadius: '20px', border: '2px solid #20B8FF', color: '#20B8FF', fontWeight: 'bold'}}>
                    Dashboard
              </Button>
              </NavLink>
          </NavItem>
        }else{
          return<NavItem>
              <NavLink></NavLink>
          </NavItem>
        }
      }

      this.SelectNavbarRender = () => {

          return<Navbar  color="#020B30" light expand="md" sticky='top' style={{backgroundColor: '#020B30', marginBottom: '0px'}}>
            <NavbarBrand href="/" style={{marginLeft: '10px', marginTop: '3px', textAlign: 'left'}}>
              <div style={{width: '120px'}}>
                <img src={Logo} style={{width: '100%'}}/>
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} style={{color: '#ffffff', backgroundColor: '#F5F5F5'}}/>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>                
                <NavItem>
                    <NavLink>
                    <Button id='home' href="/" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        Home
                    </Button>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink>
                    <Button id='programs' href="/seminars" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        Seminars
                    </Button>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem> */}
                <NavItem>
                    <NavLink>
                    <Button id='about' href="/about" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        About
                    </Button>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink>
                    <Button id='beyond-motivation' href="/videos" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        Videos
                    </Button>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink>
                    <Button id='online-classes' href="/programs" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        Programs
                    </Button>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink>
                    <Button id='virtual-bookings' href="/virtual-bookings" style={{backgroundColor: 'inherit', border: 'none', color: '#ffffff', fontWeight: 'bold'}}>
                        Virtual Bookings
                    </Button>
                    </NavLink>
                </NavItem>
                <this.ValidateSignin/>
              </Nav>
            </Collapse>
          </Navbar>
      }

      this.toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
      };

      this.dtoggle = () => {
        this.setState(prevState => ({
          dropdownOpen2: !prevState.dropdownOpen
        }));
      }

      this.onMouseEnter = () => {
        this.setState({dropdownOpen: true});
      };
    
      this.onMouseLeave = () => {
        this.setState({dropdownOpen: false});
      };
     
      this.Signout = () =>{
        const { cookies } = this.props;

        axios.get(Backend_Url + 'deactivateAccessToken/' + cookies.get('token'), { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
        .then(res => {
          cookies.remove('token', { path: '/' });
          let port = (window.location.port ? ':' + window.location.port : '');
          window.location.href = '//' + window.location.hostname + port + '/signin';
        })
        .catch((error) => {
          alert('A network error occured while trying to sign you out, please try again.')
        });
      };

      this.ValidateSignin = () => {
        const { cookies } = this.props;
        if(cookies.get('token')==null){
            return<Nav className="ml-auto" navbar>
            </Nav>
          }else{
            var user_details = this.state.user_details;
            return<Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                <this.AdminRender/>
                <NavItem>
                    <NavLink></NavLink>
                </NavItem>
                <NavItem>
                  <Dropdown className="d-inline-block" onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.dtoggle}>
                    <DropdownToggle  style={{marginTop: '', backgroundColor:  'inherit', border: 'none', fontSize: '10px', color: '#ffffff'}}>
                        {user_details.firstname} {user_details.lastname}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem  onClick={this.Signout}>
                            <NavLink style={{color: 'black', backgoundColor: 'inherit'}} >
                                Signout
                            </NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
            </Nav>
          }
      };
    }

    componentDidMount() {
      const { cookies } = this.props;

      axios.get(Backend_Url + 'getUserDetailsByAccessToken/' + cookies.get('token'), { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
      .then(res => {
        this.setState({
          user_details: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }




    render() {
      return (
        <>
          <this.SelectNavbarRender/>
        </>
      );
    }

  };
  
  export default withCookies(NavBar);