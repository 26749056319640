import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image_7 from '../images/image_7.jpg'
import Image_8 from '../images/image_8.jpg'
import Image_9 from '../images/image_9.jpg'
import Image_10 from '../images/image_10.jpg'
import Image_11 from '../images/image_11.jpg'
import Image_12 from '../images/image_12.jpg'
import Image_16 from '../images/image_16.jpg'
import Image_18 from '../images/image_18.jpg'
import AccountingForSuccessDoc from '../docs/Accounting for success.pdf'
import KidsDoc from '../docs/Kids.pdf'
import MentorshipPackageDoc from '../docs/Mentorship package.pdf'
import HowToSpeakEffectivelyDoc from '../docs/Welcome.pdf'


class Classes extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#FFFFFF'}}>
        <Helmet>
          <title>Programs | Beyond Motivation</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
          <br/><br/>
          <Row>
              <Col sm='6'>
                <img src={Image_11} style={{width: '100%'}} />
              </Col>
              <Col style={{textAlign: 'left'}}>
                  <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                      How To Speak Effectively (Course) £60
                  </h4>
                  <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                    How to speak effectively
                  </p>
                  <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                    The syllabus focuses on reading and writing but life focuses on speaking. By results alone, this course has 
                    proven to be the best speaking course many have ever encountered. These classes are for anyone who feels they 
                    need to build their confidence, to learn to speak to small crowds, to large crowds, anyone who wants to have a 
                    better presence in an interview, wants to present better at work or school, preach, teach and/or converse in 
                    any environment. This 10-session course is provided live online with the class material being provided to you, 
                    so you have it for life. You will also be given access to the video recordings while registered. Please click 
                    on link for welcome package <a href={HowToSpeakEffectivelyDoc}>{window.location.hostname}{HowToSpeakEffectivelyDoc}</a>
                  </p>
                  <Button href='/registration/How To Speak Effectively (Course)' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                    REGISTER NOW
                  </Button>
              </Col>
          </Row>
          <Row style={{marginTop: '160px'}}>
              <Col style={{textAlign: 'left'}}>
                  <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                      One on One How To Speak Effectively (Course) £100
                  </h4>
                  <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                    How to speak effectively
                  </p>
                  <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                    The syllabus focuses on reading and writing but life focuses on speaking. By results alone, this course has 
                    proven to be the best speaking course many have ever encountered. These classes are for anyone who feels they 
                    need to build their confidence, to learn to speak to small crowds, to large crowds, anyone who wants to have a 
                    better presence in an interview, wants to present better at work or school, preach, teach and/or converse in 
                    any environment. This 5-session course is provided live online with the class material being provided to you, 
                    so you have it for life. You will also be given access to the video recordings while registered. Please click 
                    on link for welcome package <a href={HowToSpeakEffectivelyDoc}>{window.location.hostname}{HowToSpeakEffectivelyDoc}</a>
                  </p>
                  <Button href='/registration/One on One How To Speak Effectively (Course)' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                    REGISTER NOW
                  </Button>
              </Col>
              <Col sm='6'>
                <img src={Image_9} style={{width: '100%'}} />
              </Col>
          </Row>
        </Container>
        <div style={{backgroundColor: '#EEECEC', marginTop: '120px'}}>
          <br/><br/>
          <Container>
            <Row>
                <Col sm='6'>
                  <img src={Image_8} style={{width: '100%'}} />
                </Col>
                <Col style={{textAlign: 'left'}}>
                    <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                        How To Speak Effectively for kids £55
                    </h4>
                    <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                      How to speak effectively for kids
                    </p>
                    <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                      Build your confidence at a young age and you are set for life. These classes will teach your little ones how 
                      to speak with a difference in various setups, to be bold and to use words in a way which connects with people. 
                      A life of confidence and good speech is a life of opportunities. The syllabus focuses on reading and writing 
                      but life focuses on speaking. This course is provided live online with the class material being provided to you, 
                      so you have it for life. You will also be given access to the video recordings while registered. Please click on 
                      link for welcome package <a href={KidsDoc}>{window.location.hostname}{KidsDoc}</a>
                    </p>
                    <Button href='/registration/How To Speak Effectively for kids' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                      REGISTER NOW
                    </Button>
                </Col>
              </Row>
          </Container>
          <br/><br/>
        </div>
        <Container> 
          <Row style={{marginTop: '160px'}}>
              <Col style={{textAlign: 'left'}}>
                  <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                      One on One Interview Coaching £70
                  </h4>
                  <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                    One on One Interview Coaching
                  </p>
                  <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                    Many people can do the job; they just can't get the job. And that is because of the interview. You can learn 
                    how to pass any interview, both the knowledge required and the delivery. It will probably surprise you that 
                    you actually receive the questions to the interview before the day. Yes, your potential employer provides them, 
                    but it takes skill to decode the message. From teaching you the right way to practise to making yourself 
                    memorable, this skill will change your life. Register to gain a skill through two one on one online live 
                    trainings that will carry you for life. 
                  </p>
                  <Button href='/registration/One on One Interview Coaching' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                    REGISTER NOW
                  </Button>
              </Col>
              <Col sm='6'>
                <img src={Image_7} style={{width: '100%'}} />
              </Col>
          </Row>
          <Row style={{marginTop: '160px'}}>
              <Col sm='6'>
                <img src={Image_10} style={{width: '100%'}} />
              </Col>
              <Col style={{textAlign: 'left'}}>
                  <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                      The Mentorship Class £30/Month
                  </h4>
                  <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                    The Mentorship Class
                  </p>
                  <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                    You don't know how high you can jump until someone places fire under your feet. The Mentorship Class is for 
                    anyone who knows they want more from life but needs guidance, teaching and accountability. TMC focuses on 
                    extracting the best out of you in all facets of life through mentorship sessions and shared trackers of your 
                    daily targets and goals you are supposed to meet. You can positively shift your life, your profitability, your 
                    impact, your growth when you join the mentorship class. It is an easy way to ensure commitment to your purpose. 
                    Please click on link for welcome package <a href={MentorshipPackageDoc}>{window.location.hostname}{MentorshipPackageDoc}</a>
                  </p>
                  <Button href='/registration/The Mentorship Class' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                    REGISTER NOW
                  </Button>
              </Col>
          </Row>
        </Container>
        <div style={{backgroundColor: '#EEECEC', marginTop: '120px'}}>
          <br/><br/>
          <Container>
            <Row>
                <Col sm='6'>
                  <img src={Image_16} style={{width: '100%'}} />
                </Col>
                <Col style={{textAlign: 'left'}}>
                    <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                        Accounting for Success £65
                    </h4>
                    <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                      Accounting For Success
                    </p>
                    <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                      Do you call it a side hustle, small business, business, self employed, entrepreneur, the bottom line is 
                      there is money that needs to be made. This is the only place where accounting becomes relevant to you, this 
                      is not for good grades but for practical success. The syllabus teaches accounting as an administrative task. 
                      We teach it as a tool for success. Numbers speak but do you understand the language and if you do, are you 
                      asking the right questions? Learn from an entrepreneur who is also a Chartered Accountant, tangible accounting 
                      tools which can boost your success. This 6-session course is provided live online with the class material being 
                      provided to you, so you have it for life. You will also be given access to the video recordings while registered. 
                      Please click on link for welcome package <a href={AccountingForSuccessDoc}>{window.location.hostname}{AccountingForSuccessDoc}</a>
                    </p>
                    <Button href='/registration/Accounting For Success' style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                      REGISTER NOW
                    </Button>
                </Col>
              </Row>
          </Container>
          <br/><br/>
        </div>
        <Container> 
          <Row style={{marginTop: '160px'}}>
              <Col style={{textAlign: 'left'}}>
                  <h4 style={{fontWeight: 'bold', color: '#020B30'}}>
                    Becoming One of The Greats £7
                  </h4>
                  <p style={{fontWeight: 'bold', color: '#020B30', fontSize: '15px', marginTop: '25px'}}>
                    Becoming One of The Greats
                  </p>
                  <p style={{textAlign: 'left', marginTop: '30px', color: '#7A7A7A'}}>
                    This is Beyond Motivation’s greatest event of the year. One not to miss. A reference point to where you shifted 
                    your view on this world, your role in this world and your impact in this life. You will learn how to have an impact 
                    in this world. Liberty ‘The Liberty Man’ Makuyana is in Harare, register to be a part of our online audience as we 
                    will be streaming live around the world.
                  </p>
                  <Button href="/registration/Becoming One of The Greats" style={{marginTop: '35px', backgoundColor: 'transparent', backgroundImage: 'linear-gradient(180deg, #4054B2 0%, #6EC1E4 100%)', width: '240px', height: '40px', border: 'none'}}>
                    REGISTER NOW
                  </Button>
              </Col>
              <Col sm='6'>
                <img src={Image_18} style={{width: '100%'}} />
              </Col>
          </Row>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(Classes);