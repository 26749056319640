import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image from '../images/speaker.png'


class Program extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
        program: {},
        name: '',
        speakers: '',
        details: '',
        loading: true,
        view: 'main'
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.UpdateProgram = () => {
        const { cookies } = this.props;
    
        var data = new FormData()
        data.append('user_access_token', cookies.get('token'))
        data.append('program_id', this.props.match.params.program_id)
        data.append('name', this.state.name)
        data.append('speakers', this.state.speakers)
        data.append('details', this.state.details)
    
        axios.post(Backend_Url + 'updateProgram', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
        .then((res) => {
          alert('Program updated successfully.')
          window.location.reload()
        }).catch((error) => {
          alert('Something went wrong while trying to update your program. Please try again.')
        })
    }

    this.DeleteProgram = () => {
        const { cookies } = this.props;
    
        var data = new FormData()
        data.append('user_access_token', cookies.get('token'))
        data.append('program_id', this.props.match.params.program_id)
    
        axios.post(Backend_Url + 'deleteProgram', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
        .then((res) => {
          alert('Seminar deleted successfully.')
          
          let port = (window.location.port ? ':' + window.location.port : '')
          window.location.href = '//' + window.location.hostname + port + '/seminar'
        }).catch((error) => {
          alert('Something went wrong while trying to delete your seminar. Please try again.')
        })      
    }

    this.ChangeView = (new_view) => {
        this.setState({view: new_view})
    }

    this.Buttons = () => {
        const { cookies } = this.props;
        if(cookies.get('token') == null){
            return<div></div>
        }else{
            return<Row style={{marginTop: '60px', marginBottom: '30px'}}>
                <Col>
                    <Button onClick={() => this.ChangeView('update')} style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                        Update Program
                    </Button>
                </Col>
                <Col>
                    <Button onClick={this.DeleteProgram} style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                        Delete Program
                    </Button>
                </Col>
            </Row>
        }
    }

    this.View = () => {
        if(this.state.view == 'main'){
            var prog = this.state.program
            return<Row style={{minHeight: '450px'}}>
            <Col sm='6'>
              <br/><br/>
              <div style={{height: '', overflow: 'hidden', width: '100%'}}>
                  <img  src={Backend_Url + 'images/' + prog.image} style={{width: '100%'}}/>
              </div>
              <br/>
            </Col>
              <Col>
              <br/><br/><br/>
              <Container style={{textAlign: 'left'}}>
                  <h5 style={{fontWeight: 'bold', color: '#020B30', textAlign: 'left'}}>
                  {prog.name}
                  </h5>
                  <br/>
                  <h6 style={{fontWeight: 'bold', textAlign: 'left'}}>
                  {prog.speakers}
                  </h6>
                  <br/>
                  <p>
                  {prog.details} 
                  </p>
                  <br/><br/><br/>
              </Container>
              <br/><br/><br/>
              </Col>
          </Row>
        }else{
            return<Container>
                <h4>
                    Update Program
                </h4>
                <br/><br/>
                <Row>
                <Col>
                    <Input name='name' value={this.state.name} type='text' onChange={this.HandleChange} placeholder='Program name'
                    style={{border: 'none', borderBottom: '1px solid silver'}}/>
                </Col>
                <Col>
                    <Input name='speakers' value={this.state.speakers} type='text' onChange={this.HandleChange} placeholder='Program speakers'
                    style={{border: 'none', borderBottom: '1px solid silver'}}/>
                </Col>
                </Row>
                <br/><br/>
                <Row>
                <Col sm='6' style={{textAlign: 'left', color: 'grey'}}>

                </Col>
                <Col>
                    <textarea name='details' value={this.state.details} type='text' onChange={this.HandleChange} placeholder='Program details'
                    style={{border: 'none', borderBottom: '1px solid silver', width: '100%'}} rows='5'></textarea>
                </Col>
                </Row>
                <br/><br/><br/>
                <Row>
                <Col>
    
                </Col>
                <Col>
                    <Button onClick={this.UpdateProgram} style={{border: 'none', borderRadius: '20px', width: '60%', backgroundColor: '#020B30', color: '#ffffff'}}>
                        Update Program
                    </Button>
                </Col>
                </Row>
            </Container>
        }
    }
  }

  componentDidMount() {
    const { cookies } = this.props;

    var data = new FormData()
    data.append('user_access_token', cookies.get('token'))
    data.append('program_id', this.props.match.params.program_id)
    
    axios.post(Backend_Url + 'program', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
    .then((res) => {
      let result = res.data
      this.setState({
          program: result, 
          loading: false,
          name: result.name,
          speakers: result.speakers,
          details: result.details
        })
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
     
    if(this.state.loading == true){
      return(
        <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
          <Helmet>
            <title>Seminar | Liberty Makuyana</title>
            {/* <meta name="description" content="" /> */}
          </Helmet>
          <br/><br/>
          <Container>
            <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Seminar</h3>
            <br/><br/>
            <h5>
              Loading ...
            </h5>
          </Container>
          <br/><br/><br/>
        </div>
      );
    }

    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Seminar | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
          <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Seminar</h3>
          <br/><br/>
          <this.View/>
          <this.Buttons/>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(Program);