import React, { Component, useReducer } from 'react';
import {Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, FormGroup, Container, Label, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import {FaUserAlt, FaUsers, FaPhone, FaKey, FaUserLock, FaMailBulk} from 'react-icons/fa';
import Background from '../images/image_1.jpeg'
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'

  class VerifyEmail extends Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) { 
      super(props);
      this.state = {
        status: ''
      };
      this.SelectRender = () => {
          var status = this.state.status
          if(status=='already verified'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    You've already verified this email address.
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signin' style={{color: 'inherit'}}>Click here to signin.</a>
                </h5>
            </div>
          }else if(status=='verification successful'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    Your email has been verified successfully.
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signin' style={{color: 'inherit'}}>Click here to signin.</a>
                </h5>
            </div>
          }else if(status=='invalid token'){
            return<div>
                <br/>
                <h3 style={{marginTop: '150px'}}>
                    Invalid verification token.
                </h3>
                <h5 style={{marginTop: '50px'}}>
                    <a href='/signin' style={{color: 'inherit'}}>Make sure you've completed the signup process and clicked the link we sent to the email address you provided upon signing up.</a>
                </h5>
            </div>
          }else{
            return<div>
                <br/>
                <h1 style={{marginTop: '120px', fontWeight: 'bold'}}>
                  A&E
                </h1>
                <h3 style={{marginTop: '80px'}}>
                  Loading...
                </h3>
                <h6 style={{marginTop: '50px'}}>
                    Taking too long to load? Check your internet connection and reload the page.
                </h6>
            </div>
          }
      }
    }

    componentDidMount() {
      const { cookies } = this.props;
      if(cookies.get('token')!=null){
        let port = (window.location.port ? ':' + window.location.port : '');
        window.location.href = '//' + window.location.hostname + port + '/dashboard';
      }else{
        const token = this.props.match.params.token
        axios.post(Backend_Url + 'checkVerificationToken/' + token, { headers: { 'Bypass-Tunnel-Reminder': 'A&E-Client' }  })
        .then((res) => {
          let result = res.data
          if(result=='Email already verified'){
              this.setState({status: 'already verified'})
          }
          if(result=='Verification successful'){
              this.setState({status: 'verification successful'})
          }
          if(result=='Invalid verification token'){
              this.setState({status: 'invalid token'})
          }
        }).catch((error) => {
              console.log(error)
        })
      }
  }

    render() {
      return (
        <div style={{backgroundImage: "url(" + Background + ")"}}>
        <div style={{minHeight: '500px', backgroundColor: '#FFFFFF', opacity: 0.85}}>
            <Helmet>
            <title>Verify email - A&E</title>
            </Helmet>
            <this.SelectRender/>
        </div>
      </div>
      );
    }

  };
  
  export default withCookies(VerifyEmail);