import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'


class AddVideo extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
        frame_src: ''
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.UploadFile = (e) => {
      this.setState({image: e.target.files[0]})
    }

    this.AddVideo = () => {
      const { cookies } = this.props;
  
      var data = new FormData()
      data.append('user_access_token', cookies.get('token'))
      data.append('frame_src', this.state.frame_src)
  
      axios.post(Backend_Url + 'addVideo', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
      .then((res) => {
        alert('Video added successfully.')
        window.location.reload()
      }).catch((error) => {
        alert('Something went wrong while trying to add your video. Please try again.')
      })
    }
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Beyond Motivation | Add Video</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <Container>
          <br/>
          <h4>
            Add Video
          </h4>
          <br/><br/>
          <Row>
            <Col>
              <Input name='frame_src' value={this.state.frame_src} type='text' onChange={this.HandleChange} placeholder='Frame src stated in the frame tag from Facebook (Inside src="")'
              style={{border: 'none', borderBottom: '1px solid silver'}}/>
            </Col>
          </Row>
          <br/><br/><br/>
          <Row>
            <Col>

            </Col>
            <Col>
                <Button onClick={this.AddVideo} style={{border: 'none', borderRadius: '20px', width: '60%', backgroundColor: '#020B30', color: '#ffffff'}}>
                  Add Video
                </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

};
  
export default withCookies(AddVideo);