import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image_1 from '../images/image_1.jpeg'
import Image_2 from '../images/image_2.jpeg'
import Image_3 from '../images/image_3.jpeg'
import Image_4 from '../images/image_4.jpeg'
import Image_6 from '../images/image_6.jpeg'
import Image_13 from '../images/image_13.jpg'
import Image_14 from '../images/image_14.jpg'
import Image_15 from '../images/image_15.jpg'
import { FaHtml5 } from 'react-icons/fa';

class Home extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
      programs: [ ]
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.CheckForPrograms = () => {
      if(this.state.programs.length == 0){
        return<div></div>
      }else{
        var prog = this.state.programs[this.state.programs.length-1]
        return<Row style={{minHeight: '450px', marginRight: '0px'}}>
          <Col sm='6'>
            <br/><br/>
            <div style={{height: '', overflow: 'hidden', textAlign: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
                <img id={prog._id.$oid} src={Backend_Url + 'images/' + prog.image} style={{width: '70%'}}/>
            </div>
            <br/>
          </Col>
          <Col>
            <br/><br/>
            <Container style={{textAlign: 'left'}}>
              <h2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                Latest Program
              </h2>
              <br/><br/><br/>
              <h5 style={{fontWeight: 'bold', color: '#020B30', textAlign: 'left'}}>
                {prog.name}
              </h5>
              <br/>
              <h6 style={{fontWeight: 'bold', textAlign: 'left'}}>
                {prog.speakers}
              </h6>
              <br/>
              <p>
                {prog.details} 
              </p>
              <br/><br/><br/>
              <Button href={'/seminar/'+prog._id.$oid} style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                READ MORE
              </Button>
            </Container>
            <br/><br/><br/><br/>
          </Col>
        </Row>
      }
    }
  }

  componentDidMount() {
    const { cookies } = this.props;

    var data = new FormData()
    data.append('user_access_token', cookies.get('token'))

    axios.post(Backend_Url + 'programs', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
    .then((res) => {
      let result = res.data
      this.setState({programs: result, loading: false})
    }).catch((error) => {
      console.log(error)
    })
  }




  render() {
    // device is a mobile
    if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      var height_div = '350px'
      var height_image = '100%'
      var width_image = '160%'
      var margin_top = ''
      var left = '10px'
      var top = '180px'
      var font_size = '20px'
      var image_margin_left = '-140px'
    }else{
      var height_div = '500px'
      var height_image = '170%'
      var width_image = '100%'
      var margin_top = '-100px'
      var left = '50px'
      var top = '250px'
      var font_size = ''
      var image_margin_left = '0px'
    }
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Home | Beyond Motivation</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <Row style={{color: '#ffffff', height: height_div, marginRight: '0px'}}>
          <div style={{height: height_div, overflow: 'hidden', width: '100%', backgroundColor: '#020B30'}}>
            <img src={Image_14} style={{marginLeft: image_margin_left, width: width_image, height: height_image, marginTop: margin_top, opacity: 0.6}}/>
          </div>
          <h3 style={{position: 'absolute', top: top,left: left, textAlign: 'left', fontSize: font_size}}>
            ‘We don’t do this to earn a living, we do this because it’s why we are living’
            <br/>
            -	Liberty Makuyana
          </h3>
        </Row>
        <br/>
        <Row style={{minHeight: '450px', marginRight: '0px'}}>
          <Col sm='6'>
            <br/><br/><br/>
            <h5 style={{fontWeight: 'bold', color: '#020B30'}}>
              Beyond Motivation
            </h5>
            <br/>
            {/* <h6 style={{fontWeight: 'bold'}}>
              Seasoned speaker
            </h6> */}
            <br/>
            <Container style={{textAlign: 'left'}}>
              <p>
                Our core belief in Beyond Motivation is that there is a reason why every single person was created, and it is in the fulfilment of this 
                purpose in which Life finds meaning. The principles and displayed teachings apply to individuals, organisations, communities, nations, and 
                the world at large.
              </p>
              <br/><br/><br/><br/>
              <Button href='/about' style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                READ MORE
              </Button>
            </Container>
          </Col>
          <Col>
            <div style={{height: '450px', overflow: 'hidden', width: '100%'}}>
              <img src={Image_15} style={{width: '100%'}} />
            </div>
          </Col>
        </Row>
        <Row style={{marginRight: '0px', backgroundColor: '#000000', minHeight: '450px', color: '#ffffff'}}>
          <Col sm='6'>
            <img src={Image_13} style={{width: '100%'}} />
          </Col>
          <Col>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <h2 style={{fontWeight: 'bold'}}>
              Programs
            </h2>
            <br/>
            <p style={{textAlign: 'left', color: '#E9F8E1'}}>
              The syllabus focuses on reading and writing, the world focuses on speaking.
            </p>
            <br/><br/>
            <h5 style={{color: '#D8A015', textAlign: 'left', fontWeight: 'bold'}}>
              How to Speak Effectively
            </h5>
            <br/>
            <div style={{textAlign: 'left'}}>
              <Button href='/programs' style={{border: 'none', backgroundColor: '#020B30', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                REGISTER
              </Button>
              <br/><br/><br/>
            </div>
          </Col>
        </Row>
        <this.CheckForPrograms/>
      </div>
    );
  }

};
  
export default withCookies(Home);